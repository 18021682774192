import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css';

const Home = () => {
    const navigate = useNavigate();

    return (
        <div className="home">
            <h1 className="home-title">
               Kianna Explains AI✨
            </h1>
            <div className="home-box">
                <div className="home-text">
                <p>Hey! I'm Kianna, a data science and engineering graduate student. I've felt compelled to share my learnings, discoveries, and insights, making AI accessible for everyone. 
                Though I'm still learning every day, I've gathered a lot of knowledge that I believe I can use to help others understand AI. AI is a powerful tool, but it can also seem a bit intimidating. That's 
                why it's my goal is to help you understand the incredible possibilities of AI, its limitations, and the ethical challenges it brings. It's a part of our daily lives and everyone deserves to understand it.
                I hope to spark discussions around these topics and encourage a responsible, ethical approach to AI.</p>
                    <b className="home-tagline"></b>
                    <b className="home-tagline">
                        Breaking down AI for all. 🌸 Championing AI literacy & ethics. 💐 Engaging in conversations for responsible AI use. 🌺.
                    </b>
                </div>
            </div>
            <p>
                <button type="button" className="home-about-button" onClick={() => navigate('/about')}>
                    Learn More About Kianna
                </button>
            </p>
        </div>
    );
}

export default Home;


