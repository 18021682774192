import React from 'react';
import './CheatSheets.css';

const learning = [
    {term: "Data Professor", definition: "A popular YouTube channel hosted by a data science professional, providing an assortment of informative tutorials and projects suited especially for beginners embarking on a data science journey. ", 
        sources: ["https://www.youtube.com/@DataProfessor"],
        sourceTitles: ["Data Professor Youtube Channel"]},
    {term: "FreeCodeCamp", definition: "An accessible and free platform that offers comprehensive guides and tutorials in data science and other areas of tech.", 
        sources: ["https://www.youtube.com/@freecodecamp", "https://www.freecodecamp.org/learn"],
        sourceTitles: ["FreeCodeCamp Youtube Channel", "FreeCodeCamp Website"]},
];

learning.sort((a, b) => a.term.localeCompare(b.term));

const Learning = () => {
    return (
        <div className="cheatsheet">
            <h1 className="cheatsheet-title"> Learning ✨</h1>
            <p className="cheatsheet-subtitle">Check out some really helpful resources for anyone interested in data science.</p>
            <div className="cheatsheet-content">
                {learning.map((item, index) => 
                    <div className="definition" key={index}>
                        <h2 className='term-title'>{item.term}</h2>
                        <p>{item.definition}</p>
                        <p className='source-text'>Sources:</p>
                        <ul className='source-list'>
                            {item.sources.map((source, i) => <li key={i}><a href={source} style={{color: '#0000FF'}}>{item.sourceTitles[i]}</a></li>)}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    )
}



export default Learning;
