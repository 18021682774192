import React from 'react';
import MediumArticlePreview from './MediumArticlePreview';
import './Medium.css';
const Medium = () => {
    const articles = [
        {
            title: "So... What is AI Anyways?",
            summary: "Over the past year, I've become the go-to person for all things AI among my friends and family. Most people I know hear about AI from the news or social media. Considering the sensationalization of AI, I have been seeing over the past year or so, I find that quite concerning. So...",
            articleUrl: "https://kiannacanexplain.substack.com/p/so-what-is-ai-anyways?utm_source=profile&utm_medium=reader2",
            color: "#96c9dc", // second color
        },
        {
            title: "From Economics to Bioinformatics: My Unconventional Path",
            summary: "Nearly three years ago, I walked onto my college campus, excited to start my economics major and ready to become an economist. Now, in August 2023, I will be starting my data science master’s program, researching bioinformatics...",
            articleUrl: "https://kiannacanexplain.substack.com/p/from-economics-to-bioinformatics?utm_source=profile&utm_medium=reader2",
            color: "#89BD9C", // first color
        }
        // Add more articles as needed
    ];

    return (
        <div className="medium-container">
            <h1 className="medium-title">My Articles and Analyses ✨</h1>
            <div className="medium">
                {articles.map((article) => (
                    <MediumArticlePreview 
                        title={article.title}
                        summary={article.summary}
                        articleUrl={article.articleUrl}
                        color={article.color}
                    />
                ))}
            </div>
        </div>
    );
}




export default Medium;
