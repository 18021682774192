import React from 'react';
import './Form.css';
import './App.css';

class Form extends React.Component {
  render() {
    return (
      <div className="form-container">
        <p className="form-title"><em>Sign up for my newsletter!</em></p>
        <p className="form-subtitle">Stay up-to-date with the latest AI topics and articles, directly in your inbox. Never miss an update!</p>
        <form name="contact" method="POST" data-netlify="true" action="/success">
            <input type="hidden" name="form-name" value="contact" /> {/* Include this line */}
            <p>
                <label>
                    <input className="form-input" type="email" name="email" placeholder="Your email" />
                </label>   
            </p>
            <p>
                <button type="submit" className="website-button">Send</button>
            </p>
        </form>

      </div>
    );
  }
}

export default Form;
