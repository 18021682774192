import React from 'react';
import './Success.css';

class Success extends React.Component {
  render() {
    return (
      <div className="success-container">
        <p className="success-title"><em>Thank you for subscribing!</em></p>
        <p className="success-subtitle">Your confirmation email will arrive shortly. Stay tuned for the latest AI updates straight to your inbox!</p>
      </div>
    );
  }
}

export default Success;

