import React, { useState, useEffect } from 'react';
import logo from './logo.png';
import { FiMenu } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import './App.css';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isCheatSheetsDropdownOpen, setCheatSheetsDropdownOpen] = useState(false);
  const [isResourcesDropdownOpen, setResourcesDropdownOpen] = useState(false); // New state
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 915);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 915);
      if (window.innerWidth > 915) {
        setCheatSheetsDropdownOpen(false);
        setResourcesDropdownOpen(false); // Close resources dropdown on resize
      }
    };

    window.addEventListener('resize', handleResize);
      
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }

    if (!isOpen) {
      setCheatSheetsDropdownOpen(false);
      setResourcesDropdownOpen(false); // Close resources dropdown if nav is closed
    }
  }, [isOpen]);

  return (
    <div className="Navbar">
      <Link to="/" className="logo-link">
        <img src={logo} alt="logo" className="logo" onClick={() => { setIsOpen(false); setCheatSheetsDropdownOpen(false); setResourcesDropdownOpen(false); }} />
      </Link>

      <div className="menu-icon" onClick={() => setIsOpen(!isOpen)}>
        <FiMenu size={30} /> 
      </div>

      <ul className={`nav-links ${isOpen ? "show" : ""}`}>
        {!isDesktop && <li className="close-menu-icon" onClick={() => setIsOpen(false)}>X</li>}
        <Link to="/" className="nav-link" onClick={() => { setIsOpen(false); setCheatSheetsDropdownOpen(false); setResourcesDropdownOpen(false); }}>Home</Link>
        <Link to="/articles" className="nav-link" onClick={() => { setIsOpen(false); setCheatSheetsDropdownOpen(false); setResourcesDropdownOpen(false); }}>Articles</Link>
        
        {/* New Resources Dropdown */}
        <div className="dropdown">
          <button className="dropbtn" onClick={() => { setResourcesDropdownOpen(!isResourcesDropdownOpen); }}>
            Resources
            <i className="fa fa-caret-down"></i>
          </button>
          {isResourcesDropdownOpen && 
          <div className="dropdown-content">
            <Link to="/resources/organizations" onClick={(e) => { e.stopPropagation(); setIsOpen(false); setResourcesDropdownOpen(false); }}>Organizations</Link>
            <Link to="/resources/reading" onClick={(e) => { e.stopPropagation(); setIsOpen(false); setResourcesDropdownOpen(false); }}>Reading</Link>
            <Link to="/resources/learning" onClick={(e) => { e.stopPropagation(); setIsOpen(false); setResourcesDropdownOpen(false); }}>Educational</Link>
          </div>}
        </div>
        
        <div className="dropdown">
          <button className="dropbtn" onClick={() => { setCheatSheetsDropdownOpen(!isCheatSheetsDropdownOpen); }}>
            AI Guides
            <i className="fa fa-caret-down"></i>
          </button>
          {isCheatSheetsDropdownOpen && 
          <div className="dropdown-content">
            <Link to="/aiguides/essentials" onClick={(e) => { e.stopPropagation(); setIsOpen(false); setCheatSheetsDropdownOpen(false); }}>AI Essentials</Link>
            <Link to="/aiguides/deepdive" onClick={(e) => { e.stopPropagation(); setIsOpen(false); setCheatSheetsDropdownOpen(false); }}>AI Deep Dives</Link>
          </div>}
        </div>
        

        <Link to="/about" className="nav-link" onClick={() => { setIsOpen(false); setCheatSheetsDropdownOpen(false); setResourcesDropdownOpen(false); }}>About</Link>
      </ul>
      { isDesktop && <a href="https://www.iamkianna.com" className="nav-link website-button" target="_blank" rel="noopener noreferrer">Kianna's Portfolio</a> }
      { !isDesktop && isOpen && <a href="https://www.iamkianna.com" className="nav-link website-button" target="_blank" rel="noopener noreferrer">Kianna's Portfolio</a> }
    </div>
  );
}

export default Navbar;
// not needed rn
// <Link to="/resources/social-media" onClick={(e) => { e.stopPropagation(); setIsOpen(false); setResourcesDropdownOpen(false); }}>Kianna's Media</Link>