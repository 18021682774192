import React from 'react';
import './CheatSheets.css';

const reading = [
    {term: "These Women Tried to Warn Us About AI", definition: "Check out the content below.", 
        sources: ["https://www.rollingstone.com/culture/culture-features/women-warnings-ai-danger-risk-before-chatgpt-1234804367/"],
        sourceTitles: ["Rolling Stone"]},
    {term: "The Exploited Labor Behind Artificial Intelligence", definition: "Check out the content below.", 
        sources: ["https://www.noemamag.com/the-exploited-labor-behind-artificial-intelligence/"],
        sourceTitles: ["Noema Magazine"]},
];

reading.sort((a, b) => a.term.localeCompare(b.term));

const Reading = () => {
    return (
        <div className="cheatsheet">
            <h1 className="cheatsheet-title"> Reading ✨</h1>
            <p className="cheatsheet-subtitle">Check out import articles and papers regarding AI and its societal implications.</p>
            <div className="cheatsheet-content">
                {reading.map((item, index) => 
                    <div className="definition" key={index}>
                        <h2 className='term-title'>{item.term}</h2>
                        <p>{item.definition}</p>
                        <p className='source-text'>Sources:</p>
                        <ul className='source-list'>
                            {item.sources.map((source, i) => <li key={i}><a href={source} style={{color: '#0000FF'}}>{item.sourceTitles[i]}</a></li>)}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    )
}



export default Reading;
