import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet
import Marquee from './Marquee';
import Navbar from './Navbar';
import Home from './Home';
import About from './About';
import Essentials from './Essentials';
import DeepDive from './DeepDive';
import SpecialTopics from './SpecialTopics';
import Organizations from './Organizations';
import Reading from './Reading';
import Learning from './Learning';
import Success from './Success';
import Medium from './Medium';
import Media from './Media';
import Footer from './Footer';
import Form from './Form';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';
import { Routes, Route } from 'react-router-dom';

function App() {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    function adjustPadding() {
      var navbar = document.querySelector('.Navbar');
      var homeContainer = document.querySelector('.home-container');
      if (navbar && homeContainer) {
        var navbarHeight = navbar.offsetHeight;
        homeContainer.style.paddingTop = navbarHeight + 'px';
      }
    }

    window.onload = adjustPadding;
    window.onresize = () => {
      setWindowSize(window.innerWidth);  // Force a re-render
      adjustPadding();
    };
  }, [windowSize]);  // Add windowSize as a dependency to the useEffect


  return (
    <div className="App">
      <Helmet>
        <meta name="keywords" content="Kianna Hendricks" />
      </Helmet>
      <Marquee />
      <Navbar />
      <div className="home-container">
        <Routes>
          <Route path="/" element={<><Home /><Form /></>} />
          <Route path="/about" element={<About />} />
          <Route path="/aiguides/essentials" element={<Essentials />} />
          <Route path="/aiguides/deepdive" element={<DeepDive />} />
          <Route path="/aiguides/deepdive" element={<SpecialTopics />} /> 
          <Route path="/articles" element={<Medium />} />
          <Route path="/success" element={<Success />} />
          <Route path="/resources/organizations" element={<Organizations />} />
          <Route path="/resources/reading" element={<Reading />} />
          <Route path="/resources/learning" element={<Learning />} />
          
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
// not needed rn
// <Route path="/resources/social-media" element={<Media />} /> 