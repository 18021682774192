import React from 'react';
import './SpecialTopics.css';

const SpecialTopics = () => {
    return (
        <div className="cheatsheet">
            <h1 className="cheatsheet-title">AI Deep Dive Cheat Sheet ✨</h1>
            <div className="cheatsheet-content">
                <h2>Coming Soon!</h2>
                <p>I am working on compiling a comprehensive cheat sheet for more advanced AI topics. Here's a sneak peek at what to expect:</p>
                <ul>
                    <li><strong>Human Centered Artificial Intelligence</strong></li>
                    <li><strong>Reinforcement Learning</strong></li>
                    <li><strong>Language Models (LM)</strong></li>
                    <li><strong>Explainability</strong></li>
                    <li><strong>Artificial General Intelligence</strong></li>
                </ul>
                <p>Stay tuned for updates!</p>
            </div>
        </div>
    );
}



export default SpecialTopics;