import React from 'react';
import './App.css';

function Marquee() {
    const text = "🌸 Demystifying  AI for everyone, regardless of technical background.  🌷 Stay tuned for my deep dive into AI! 💐 Learn about AI literacy and ethics with me.";
    
    return (
      <div className="marquee">
        <span className="marquee-content" data-text={text}>
          {text}
        </span>
      </div>
    );
  }
  

export default Marquee;
