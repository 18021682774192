import React from 'react';
import './About.css';

const About = () => {
    return (
        <div className="about">
            <h1 className="about-title">About Kianna ✨</h1>
            <div className="about-content">
                <p><i>Dear Reader</i>,</p>
                <div className="about-text">
                    <p>In 2021, I took a 'Data Skills for Economics' course that led me on a path to learning more and more about data science.
                        That was the spark that ignited my passion for using data to create meaningful change.
                        Since then, I've dedicated much of my time to teaching myself programming and data science skills alongside my economics degree.
                        Fast-forward to today, I am now a graduate student in data science and engineering.</p>
                    <p>But why did I create this platform? Recently, I've found myself fielding questions about AI and its various applications. 
                        As my knowledge grows, I felt compelled to share it. My mission is simple: to break down complex AI topics and present 
                        them in a way that's accessible, understandable, and useful for everyone. I want this platform to be a space where we 
                        can engage in informed discussions, challenge assumptions, and explore the ethical nuances of AI. </p>
                    <p>My hope is that this platform can be a resource for everyone. I invite you to join me as we explore the fascinating 
                        world of AI and work together to ensure its responsible, ethical use.</p>
                </div>
                <p><i>Sincerely</i>, <br /> Kianna <br /> Founder and Developer</p>
            </div>
        </div>
    );
}

export default About;
