import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTiktok, faTwitter } from '@fortawesome/free-brands-svg-icons';
import '@fortawesome/fontawesome-svg-core/styles.css';
import './App.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="left-section">Copyright &copy; 2024</div>
      <div className="middle-section">
        <a href="https://www.instagram.com/kiannaexplainsai" target="_blank">
          <FontAwesomeIcon icon={faInstagram} size="2x" />
        </a>
        <a href="https://www.twitter.com/kiannacancode" target="_blank">
          <FontAwesomeIcon icon={faTwitter} size="2x" />
        </a>
        <a href="https://www.tiktok.com/@kiannacancode?_t=8ixXzrtrVRy&_r=1" target="_blank">
          <FontAwesomeIcon icon={faTiktok} size="2x" />
        </a>
      </div>
      <div className="right-section">Designed and Coded by Kianna Hendricks</div>
    </footer>
  );
}

export default Footer;
