import React from 'react';
import './MediumArticlePreview.css';
import './App.css';

const MediumArticlePreview = ({ title, summary, articleUrl, color }) => {
    return (
        <div className="article-preview" style={{backgroundColor: color}}>
            <h2 className="article-preview-title">{title}</h2>
            <p>{summary}</p>
            <a href={articleUrl} target="_blank" rel="noopener noreferrer" className="article-preview-button">Read</a>
        </div>
    );
}


export default MediumArticlePreview;
