import React from 'react';
import './CheatSheets.css';
import MediumArticlePreview from './MediumArticlePreview';
import './Medium.css';

const DeepDive = () => {
    const articles = [
        {
            title: "Interpretable and Explainable AI: The Basics",
            summary: "To help you understand what interpretability and explainability mean in the context of AI and why they are important. Two important concepts help us understand how AI makes decisions: interpretability and explainability. While they are closely related, they are not the same thing. Let's explore what each term means and how they differ.",
            articleUrl: "/interpretable_explainable_guide.pdf",
            color: "#96c9dc", // second color
        },
        
    ];

    return (
        <div className="medium-container">
            <h1 className="medium-title">Deep Dive Guides ✨</h1>
            <div className="medium">
                {articles.map((article) => (
                    <MediumArticlePreview 
                        title={article.title}
                        summary={article.summary}
                        articleUrl={article.articleUrl}
                        color={article.color}
                    />
                ))}
            </div>
        </div>
    );
}


export default DeepDive;
