import React from 'react';
import './CheatSheets.css';

const organizations = [
    {term: "Algorithmic Justice League", definition: "AJL conducts research, advocates, and raises awareness about the implications of AI on society. AJL's goal is to raise the voices of the disenfranchised communities impacted by AI.", 
        sources: ["https://www.ajl.org/"],
        sourceTitles: ["Algorithmic Justice League"]}
];

organizations.sort((a, b) => a.term.localeCompare(b.term));

const Organizations = () => {
    return (
        <div className="cheatsheet">
            <h1 className="cheatsheet-title"> Organizations ✨</h1>
            <p className="cheatsheet-subtitle">Check out the organizations engaged in advocacy for ethical and responsible AI.</p>
            <div className="cheatsheet-content">
                {organizations.map((item, index) => 
                    <div className="definition" key={index}>
                        <h2 className='term-title'>{item.term}</h2>
                        <p>{item.definition}</p>
                        <p className='source-text'>Sources:</p>
                        <ul className='source-list'>
                            {item.sources.map((source, i) => <li key={i}><a href={source} style={{color: '#0000FF'}}>{item.sourceTitles[i]}</a></li>)}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    )
}



export default Organizations;
