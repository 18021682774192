import React from 'react';
import './CheatSheets.css';

const definitions = [
    {term: "Artificial Intelligence (AI)", definition: "The science of making machines or software mimic human behavior and intelligence. It aims to create systems that perform tasks requiring human intellect, such as learning, reasoning, and problem-solving, while also enabling machines to learn and adapt like humans. Example: AI is used in voice recognition systems like Amazon's Alexa or Apple's Siri.", 
        sources: ["https://www.ibm.com/topics/artificial-intelligence", "https://www.britannica.com/technology/artificial-intelligence", "https://hai.stanford.edu/sites/default/files/2020-09/AI-Definitions-HAI.pdf"],
        sourceTitles: ["Artificial Intelligence, IBM", "Artificial Intelligence, Britannica", "'Artificial Intelligence Defintions', Stanford University HAI"]},
    {term: "Machine Learning (ML)", definition: "A branch of AI that uses data and algorithms to imitate human learning, steadily increasing its prediction accuracy over time. It equips machines to learn from experiences and improve, identifying patterns and predicting outcomes for efficient problem-solving. Example: ML powers the recommendation systems on platforms like YouTube or TikTok, which suggest videos you might enjoy based on your past activity.", 
        sources: ["https://link.springer.com/chapter/10.1007/978-3-030-15729-6_1", "https://mitsloan.mit.edu/ideas-made-to-matter/machine-learning-explained", "https://www.ibm.com/topics/machine-learning"],
        sourceTitles: ["'Machine Learning Definition and Basics' in 'Introduction to Machine Learning' by Gopinath Rebala, Ajay Ravi & Sanjay Churiwala", "Sara Brown, 'Machine learning, explained', MIT SLOAN", "'What is machine learning', IBM"]},
    {term: "Deep Learning (DL)", definition: "Uses neural networks to mimic how the human brain works to self-learn from large volumes of data. It excels at identifying intricate patterns and relationships without explicitly programming Example: DL is behind the technology of self-driving cars, which helps identify and differentiate between other vehicles, pedestrians, and road signs.", 
        sources: ["https://www.geeksforgeeks.org/introduction-deep-learning/", "https://www.coursera.org/articles/what-is-deep-learning", "https://www.mathworks.com/discovery/deep-learning.html", "https://aws.amazon.com/what-is/deep-learning/"],
        sourceTitles: ["'Introduction to Deep Learning', Geeks for Geeks", "'What is Deep Learning? Defintions, Examples, and Careers', Coursera", "'3 things you need to know about DL', Mathworks", "'What is deep learning', Amazon Web Services"]},
    {term: "Neural Networks", definition: "A subset of machine learning (ML) modeled after the human brain and how brain neurons work function to procession information, identify patterns, and make predictions. This network of functions translates various forms of input into a desired output. These form the foundation for deep learning.", 
        sources: ["https://deepai.org/machine-learning-glossary-and-terms/neural-network", "https://hai.stanford.edu/sites/default/files/2020-09/AI-Definitions-HAI.pdf", "https://www.sciencedirect.com/science/article/abs/pii/B9780123741059004937"],
        sourceTitles: ["'Neural Network', DeepAI", "'Artificial Intelligence Defintions', Stanford University HAI", "'Neural Networks', N.L.W. Keijsers, published in Encyclopedia of Movement Disorders"]},
    {term: "Natural Language Processing (NLP)", definition: "A branch of AI that enables machines to understand, manipulate, and generate human-like text or speech. It's about building systems that can manipulate human language, bridging the gap between human communication and computer understanding. Example: NLP is the technology behind services like Google Translate, which can translate text from one language to another.", 
        sources: ["https://www.deeplearning.ai/resources/natural-language-processing/", "https://www.geeksforgeeks.org/natural-language-processing-overview/", "https://www.sciencedirect.com/science/article/abs/pii/B9780128213926000091", "https://www.oracle.com/artificial-intelligence/what-is-natural-language-processing/"],
        sourceTitles: ["'Natural Language Processing', DeepAI", "'Natural Language Processing – Overview', Geeks for Geeks", "'Chapter 6 - Sex and gender bias in natural language processing', Cirillo et al., published in Sex and Gender Bias in Technology and Artificial Intelligence", "'What is Natural Language Processing?', Orcale"]},
    {term: "Algorithm", definition: "A set of instructions or rules followed by computers to solve problems or perform specific tasks. They are the backbone of computer programming, driving solutions and operational efficiency.", 
        sources: ["https://www.geeksforgeeks.org/introduction-to-algorithms/", "https://www.simplilearn.com/tutorials/data-structure-tutorial/what-is-an-algorithm"],
        sourceTitles: ["'What is Algorithm | Introduction to Algorithms', Geeks for Geeks", "Soni, Upadhyay, 'What Is An Algorithm? Definition, Types, Characteristics', Simplilearn"]},
    {term: "Computer Vision", definition: "Replicates parts of human vision so computers can understand the visual word. Using digital images and videos, computers can accurately identify and classify objects. Example: Computer Vision is the driving technology behind features like Apple's Face ID on iPhones, which enables the phone to accurately recognize and authenticate the user's face for secure access.", 
        sources: ["https://deepai.org/machine-learning-glossary-and-terms/computer-vision", "https://www.sas.com/en_us/insights/analytics/computer-vision.html", "https://www.sas.com/en_us/insights/analytics/computer-vision.html", "https://azure.microsoft.com/en-us/resources/cloud-computing-dictionary/what-is-computer-vision/"],
        sourceTitles: ["'Computer Vision', DeepAI", "'Computer Vision: What it is and why it matters', SAS", "'What is computer vision', Microsoft Azure"]},
    {term: "Intelligence", definition: "Regarded as the ability to learn, adapt, and apply knowledge and skills to achieve goals or solve problems, especially in changing circumstances.", 
        sources: ["https://hai.stanford.edu/sites/default/files/2020-09/AI-Definitions-HAI.pdf", "https://towardsdatascience.com/what-is-intelligence-a69cbd8bb1b4", "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3341646/"],
        sourceTitles: ["'Artificial Intelligence Defintions', Stanford University HAI", "Egor Dezhic, 'What is intelligence', Towards Data Science", "'Intelligence', Robert J. Sternberg, PhD, published in Dialogues in Clinical Neuroscience"]},
    {term: "Data Mining", definition: "The process of identifying patterns, correlations, and extracting information from large data sets. It involves using algorithms to organize, sort, and filter data to transform raw information into useful knowledge. It has applications in various industries, improving decision-making, marketing strategies, reducing costs, enhancing customer relationships, detecting fraud, and more. Example: Retail companies often use data mining techniques to analyze customer purchasing behavior, identify patterns, and predict future sales trends.", 
        sources: ["https://www.sas.com/en_us/insights/analytics/data-mining.html", "https://www.investopedia.com/terms/d/datamining.asp", "https://www.ibm.com/topics/data-mining"],
        sourceTitles: ["'Data Mining: What it is and why it matteres', SAS", "Alexandra Twin, 'What Is Data Mining? How It Works, Benefits, Techniques, and Examples', Investopedia", "'What is data mining', IBM"]},
        // ... add the rest of your definitions here
    {term: "AI Ethics", definition: "Refers to principles that guide the responsible development and use of AI technology. It aims to uphold values like individual rights, privacy, non-discrimination, and non-manipulation. It extends beyond legal requirements and emphasizes respect for fundamental human values. Critical areas of AI Ethics include avoiding bias, ensuring privacy, mitigating environmental risks, and avoiding AI-induced mistakes. This field also studies how to optimize the beneficial impact of AI while minimizing risks and adverse outcomes.", 
        sources: ["https://c3.ai/glossary/artificial-intelligence/ethical-ai/", "https://www.coursera.org/articles/ai-ethics", "https://www.forbes.com/sites/nishatalagala/2022/05/31/ai-ethics-what-it-is-and-why-it-matters/?sh=69edab303537"],
        sourceTitles: ["'Ethical AI', C3.ai", "'AI Ethics: What It Is and Why It Matters', Coursera", "Nisha Talagala, 'AI Ethics: What It Is And Why It Matters', Forbes"]},
        // ... add the rest of your definitions here
    {term: "Black Box Problem", definition: "Refers to the lack of transparency in explaining how an AI system makes decisions or predictions. In a black box model, the AI receives inputs and produces outputs without revealing the internal logic that guides its decision-making process. This opacity makes it difficult to correct or improve the system when undesirable outcomes occur, understand why it made a particular decision, or ensure fairness and lack of bias in its operations. Even those who design these models often cannot fully understand how variables are combined to yield the final results due to the complex functions involved. The black box problem has significant implications, practically, ethically, and legally, making it challenging for regulatory bodies to ensure fair and secure data processing.", 
        sources: ["https://www.investopedia.com/terms/b/blackbox.asp", "https://umdearborn.edu/news/ais-mysterious-black-box-problem-explained", "https://hdsr.mitpress.mit.edu/pub/f9kuryi8/release/8", "https://arxiv.org/pdf/1903.04361.pdf", "https://theconversation.com/what-is-a-black-box-a-computer-scientist-explains-what-it-means-when-the-inner-workings-of-ais-are-hidden-203888"],
        sourceTitles: ["Will Kenton, 'What Is a Black Box Model?', Investopedia", "'AI's mysterious ‘black box’ problem, explained', University of Michigan-Dearborn", "Cynthia Rudin and Joanna Radin, 'Why Are We Using Black Box Models in AI When We Don’t Need To?', Harvard Data Science Review", "'Solving the Black Box Problem', Carlos Zednik, Otto-von-Guericke-Universität Magdeburg", "Saurabh Bagchi, 'What is a black box?', The Conversation"]},
        // ... add the rest of your definitions here
    {term: "Bias", definition: "The prejudices exhibited by AI systems due to human influences. This can occur when the data used to train these models or the rules defined by humans have inherent biases. These biases can take various forms, including algorithmic, statistical, prediction, and social bias, potentially leading to unfair outcomes or exacerbating existing societal inequalities.", 
        sources: ["https://www.acrdsi.org/DSIBlog/2023/02/14/Understanding-Bias-in-AI", "https://www.forbes.com/sites/bernardmarr/2022/09/30/the-problem-with-biased-ais-and-how-to-make-ai-better/?sh=75a026aa4770"],
        sourceTitles: ["Ali Tejani, MD, 'Understanding Bias in AI', Data Science Institute at American College of Radiology", "Bernard Marr, 'The Problem With Biased AIs (and How To Make AI Better)', Forbes"]},
        // ... add the rest of your definitions here
    {term: "Data Science", definition: "A field that uses techniques from various disciplines like mathematics, statistics, computer science, and artificial intelligence to study and extract meaningful information from large amounts of data. In simpler terms, it's about understanding and learning from data to make decisions.", 
        sources: ["https://aws.amazon.com/what-is/data-science/", "https://www.ibm.com/topics/data-science", "https://ischoolonline.berkeley.edu/data-science/what-is-data-science/"],
        sourceTitles: ["'What is Data Science', Amazon Web Services", "'What is data science?', IBM", "'What is Data Science?', Berkley School of Information"]},
        // ... add the rest of your definitions here
    {term: "Statistics", definition: "A interdisciplinary field focused on collecting, analyzing, interpreting, and presenting quantitative data. The area primarily deals with understanding uncertainty and variation in data. With its universal applicability, statistics aid decision-making across numerous fields by providing robust evidence.", 
        sources: ["https://www.investopedia.com/terms/s/statistics.asp", "https://www.stat.uci.edu/what-is-statistics/"],
        sourceTitles: ["Jim Chappelow, 'Statistics in Math: Definition, Types, and Importance', Investopedia", "'What is Statistics?', UCI Department of Statistics"]},
        // ... add the rest of your definitions here
    {term: "Computer Science", definition: "The study of how computers work, including the underlying principles and techniques used in their design. It involves creating and understanding the complex programs that make computers do what we want. This field explores everything from the mathematical theories behind computers to computer hardware and software design to practical applications in artificial intelligence, graphics, and networks. In simpler terms, it's about understanding and learning from how computers and software operate and using this knowledge to create new computer-based solutions.", 
        sources: ["https://www.mtu.edu/cs/what/", "https://www.britannica.com/science/computer-science", "https://cse.buffalo.edu/~rapaport/Papers/whatiscsapa-20170127-edited.pdf"],
        sourceTitles: ["'What is Computer Science', Michigan Tech", "'Computer Science', Britannica", "'What Is Computer Science?', William J. Rappaport, University at Buffalo"]},
        // ... add the rest of your definitions here
];

definitions.sort((a, b) => a.term.localeCompare(b.term));

const Essentials = () => {
    return (
        <div className="cheatsheet">
            <h1 className="cheatsheet-title"> AI Essentials Cheat Sheet ✨</h1>
            <p className="cheatsheet-subtitle">Definitions for key terms about artificial intelligence.</p>
            <div className="cheatsheet-content">
                {definitions.map((item, index) => 
                    <div className="definition" key={index}>
                        <h2 className='term-title'>{item.term}</h2>
                        <p>{item.definition}</p>
                        <p className='source-text'>Sources:</p>
                        <ul className='source-list'>
                            {item.sources.map((source, i) => <li key={i}><a href={source} style={{color: '#0000FF'}}>{item.sourceTitles[i]}</a></li>)}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    )
}



export default Essentials;
